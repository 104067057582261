.UserFeedbackContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000bd;
  .header {
    color: #1f203f;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  input {
    color: var(--gray-primary, #4d596c);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 161.538% */
    border: 1px solid rgba(193, 201, 214, 0.49);
    background: #fff;
    padding: 8px 10px;
    outline: none;
  }
  label {
    color: #415280;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
  }

  textarea {
    border: 1px solid rgba(193, 201, 214, 0.49);
    background: #fff;
  }

  // UserFeedback.module.scss

  .chip {
    padding: 6px 12px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    margin-bottom: 5px;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 129%; /* 18.06px */
    letter-spacing: -0.7px;
    border-radius: 56px;
    border: 1px solid rgba(65, 82, 128, 0.36);
    background: rgba(244, 244, 244, 0.41);
    // &:hover {
    //   background-color: #e0e0e0;
    // }
  }

  .chipSelected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  .actionsContainer {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    filter: drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.10));
    .cancel {
      border-radius: 5px;
      border: 1px solid #788cfc;
      color: #788cfc;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      // line-height: 21px; /* 150% */
      padding: 8px 16px;
    }

    .submit {
      border-radius: 5px;
      background: #788cfc;
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px; /* 150% */
      max-width: 172px;
      width: 100%;
      padding: 8px 16px;
    }
  }
}
