.InsuranceFormContainer {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #f0f5fc;
  padding: 40px;
  @media (max-width: 768px) {
    padding: 20px;
  }

  input {
    border: 1px solid rgba(193, 201, 214, 0.49);
    background: #fff;
    outline: none;
    padding: 8px 10px;
    color: #4d596c;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 161.538% */
  }
  label {
    color: #8c8c8c;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
  }
}
