.textBetweenLines {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #000;
  }

  .textContent {
    font-size: 12px;
    font-weight: 400;
    margin: 0px 16px;
  }
}

.exploreButton {
  border-radius: 100px;
  background: #415280;
  color: #FFF;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;

  &:hover {
      background-color: #304068;
  }

  @media (min-width: 768px) {
      display: none;
  }
}


.scanCardBtn {
  border-radius: 16px;
  background: linear-gradient(90deg, #788CFC 0%, #5061C5 100%);
  color: #FFF;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding:10px 56px;
  gap: 8px;
  min-width: 300px;
}

.uploadBtn {
  color: #788cfc;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.orText {
  color: #767676;
  text-align: center;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dragAndDropText {
  color: #1f203f;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  opacity: 0.6;
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}

.uploadedFilesHeader {
  color: #415280;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 215.385% */
}

.fileName {
  color: #232323;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.fileSize {
  color: #4a4a4a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.captureData {
  border-radius: 16px;
  background: #788cfc;
  color: #fff;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}