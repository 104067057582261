.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 1px solid #e1e1e1;
}

.companyLogo {
    height: 40px;
    width: auto;
}

.exploreButton {
    border-radius: 100px;
    background: #415280;
    color: #FFF;
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
    outline: none;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #304068;
    }

    @media (max-width: 768px) {
        display: none;
    }
}
