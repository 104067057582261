.ImagePreviewContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 40px;
    background-color: #F0F5FC;
    border-radius: 20px;
    @media (max-width: 768px) {
      padding: 10px;
    }
  }
  
  .previewWrapper {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    // width: 300px; 
    // height: 300px; 
  }
  
  .previewImage {
    width: 100%; 
    height: 100%;
    object-fit: fill; 
    border-radius: 20px;
      // width: 300px; 
    height: 300px; 
  }
  
  .unsupportedFile {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .fileIcon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
  