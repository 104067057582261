.QuickCapturePopUpContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000bd;

  .header {
    color: #1f203f;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .body {
    margin-bottom: 150px;
    .subHeader {
      color: #595959;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 112%;
      text-align: center;
      margin-top: 20px;
    }

    .desc {
      color: #737373;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      /* 215.385% */
      text-align: center;
      margin-top: 20px;
      margin-bottom: 24px;
    }

    .captureImage {
      border-radius: 6px;
      background: #788cfc;
      color: #fff;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      max-width: 172px;
      width: 100%;
    }
    .fileName {
      color: #232323;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .fileSize {
      color: #4a4a4a;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .stepsContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 120px;
    }

    .step {
      // width: 50px;
      // height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-weight: bold;
      font-size: 20px;
    }
    .line {
      flex-grow: 1;
      height: 1px;
      background-color: #4a90e2;
    }

    .label {
      margin-top: 10px;
      text-align: center;
      width: 50px;
      font-size: 14px;
    }
  }

  .actionsContainer {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    filter: drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.10));
    .cancel {
      border-radius: 5px;
      border: 1px solid #788cfc;
      color: #788cfc;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      // line-height: 21px; /* 150% */
      padding: 8px 16px;
    }

    .submit {
      border-radius: 5px;
      background: #788cfc;
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px; /* 150% */
      max-width: 172px;
      width: 100%;
      padding: 8px 16px;
    }
  }
}
