.CapturedResultContainer {
  .header {
    color: #1f203f;
    font-family: Recoleta;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .retryBtn {
    border-radius: 5px;
    border: 1px solid #788cfc;
    color: #788cfc;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 20px;
    line-height: 21px; /* 150% */
  }
  .completeBtn {
    border-radius: 5px;
    border: 1px solid #788cfc;
    background: #788cfc;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 150% */
    padding: 16px 20px;
  }
  .warnText {
    color: #415280;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 161.538% */
  }
}
