.CameraAccessPopUpContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000BD;

  .closeIcon {
    fill: #1f203f;
    opacity: 0.8;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .header {
    color: #26185d;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 112%;
    /* 24.64px */
  }

  .intro {
    color: #415280;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .actions {
    .deny {
      border-radius: 6px;
      border: 1px solid #788cfc;
      color: #788cfc;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      /* 150% */
    }

    .allow {
      border-radius: 6px;
      background: #788cfc;
      color: #fff;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}